<template>
  <section
    id="content"
    style="margin-bottom: 80px "
  >
    <v-card
      class="mx-auto py-1 mb-2"
      elevation="24"
      light
      max-width="95%"
      style="margin: -64px 70px 0px 0px"
      v-bind="$attrs"
      tile
      outlined
    >
      <v-sheet
        max-width="1000"
        class="mx-auto py-0 py-md-6"
      >
        <v-container id="vineyards">
          <v-card-title
            :class="[$vuetify.breakpoint.mdAndUp ? 'display-2' : 'headline']"
            class="justify-center font-weight-bold grey--text text--darken-3 mb-3 text-center"
          >
            History
          </v-card-title>
          <v-card-text
            class="text-left text-center subtitle-1 font-weight-light mx-auto"
          >
            <em> ~ Written by Suzy Raymond </em>
          </v-card-text>
          <v-card-text
            class="text-left text-center subtitle-2 font-weight-light mx-auto mb-5"
          >
            Ian Garland had a farm in Africa. The Amanzimnyama and Siyayi rivers
            have their confluence there. It was a paradise of swamp forest,
            papyrus marshes and deep clear pools, eventually running out through
            the Siyayi Lagoon into the sea. With time, pressure on the land
            increased, and the Siyayi catchment became degraded and the lagoon
            choked with reeds after cyclone Demoina. Ian embarked on a
            rehabilitation programme with the tremendous enthusiasm that so
            characterized his life. He planted approximately 80 000 trees,
            propagated in his nursery, on and around his farm, Twinstreams. He
            gave away tress to anybody wanting them, and so many schools,
            hospitals and clinics benefited from this little nursery. His
            right-hand man and soul mate, Jobe Mafuleka, shared Ian's enthusiasm
            for Imvelo-Nature. Jobe is extremely knowledgeable – he can identify
            trees and plants from their leaves alone. Two years ago, cataracts
            caused him to become almost totally blind. He then identified plants
            by smelling and feeling them! Fortunately, cataract surgery restored
            his sight, and he could see his beloved plants again. Over the years
            he has brought about 2 000 plants (about 150 tree species) to
            Twinstreams, where they were and are being planted. This allows
            Twinstreams Indigenous Nursery to offer many species not available
            elsewhere. These days Jobe lives a frugal and disciplined life on
            his small farm in Maputuland, but frequently visits Twinstreams to
            see his extended family and put in a few weeks work. He could be
            found chopping down aliens at midday in December, and he is 82 years
            old! Ian Garland died in 2007, and Twinstreams still has the
            Education Centre, now run by WESSA, started by Ian 55 years ago.
            Many people have benefited from this centre. When Mondi bought the
            farm, these areas were placed in trust, and Jonathan Cromhout was
            appointed to look after the nursery in 1996. In 2001 he was granted
            the opportunity to run it as a business. Today, the nursery sports
            over 500 species of plants, trees, climbers, and aloes and offers
            landscaping and maintenance services. Ian and Jobe's passion still
            motivates the people working there. <br><br>
            At Twinstreams, only organic fertilizers are used, and there is no
            spraying with poisons or chemicals. The enormous variety of wildlife
            from Gaboon Vipers to butterflies testifies that this must be the
            dictum to promote biodiversity at its best. Anyone not converted to
            indigenous gardening should visit the nursery to see such spectacles
            as the tall and elegant Plectranthus ecklonii in a haze of azure or
            pink or white, or all three, thriving in the shade. The absolutely
            brilliant Crocosmia aurea or Falling Stars, with its vibrant orange
            flowers brighten up shady places. Hiding shyly amongst its
            spectacular leaves the intense gentian blue Streptocarpus gardenia
            takes one's breath away. Karomia speciosa (formerly Holmskoldia)
            makes a beautiful substitute for bougainvillaea, flowering for
            months on end, tolerating dry, hot conditions. All these plants are
            easy to grow as Jobe would tell you.
          </v-card-text>
        </v-container>

        <v-card
          class="mx-auto"
          height="70vh"
          width="80vw"
        >
          <v-carousel
            cycle
            height="70vh"
            width="80vw"
            hide-delimiters
            show-arrows-on-hover
          >
            <v-carousel-item
              v-for="(item, i) in items"
              :key="i"
              :src="item.src"
            />
          </v-carousel>
        </v-card>

        <section
          id="details"
          class="mx-auto pt-5 pb-0 mb-2"
        >
          <v-container>
            <v-row justify="center">
              <v-col
                v-for="([icon, info1, info2, href], i) in details"
                :key="i"
                cols="12"
                md="3"
              >
                <div class="text-center">
                  <a
                    style="text-decoration: none;"
                    :href="href"
                    target="_blank"
                    rel="noopener"
                  >
                    <v-icon
                      color="thirdary"
                      size="64"
                      class="mb-3"
                      v-text="icon"
                    />

                    <div
                      color="thirdary"
                      class="mb-2 title"
                      v-text="info1"
                    />
                    <div
                      color="thirdary"
                      v-text="info2"
                    />
                  </a>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-sheet>
      <v-sheet
        class="mx-auto py-0 mb-2"
        color="grey lighten-2"
      >
        <p style="text-align: center;">
          <strong>Open</strong>
          <br>
          <em>Monday:</em> closed
          <br>
          <em>Tuesday – Friday:</em> 07:00-16:00
          <br>
          <em>Saturday & Sunday & Public Holidays:</em> 07:00-13:00
          <br>
        </p>
      </v-sheet>
    </v-card>
  </section>
</template>

<script>
  export default {
    name: 'SampleContents',

    data: () => ({
      items: [
        {
          src: require('@/assets/Main/history/history (2).webp')
        },
        // {
        //   src: require('@/assets/Main/history/history (3).webp')
        // },
        {
          src: require('@/assets/Main/history/history (4).webp')
        },
        // {
        //   src: require('@/assets/Main/history/history (5).webp')
        // },
        // {
        //   src: require('@/assets/Main/history/history (6).webp')
        // },
        {
          src: require('@/assets/Main/history/history (7).webp')
        },
        {
          src: require('@/assets/Main/history/history (8).webp')
        }
      ],
      details: [
        [
          'mdi-crosshairs-gps',
          'Twinstreams Indigenous Nursery',
          'Mtunzini, KZN, 3867',
          'https://www.google.com/maps/place/Twinstreams+Indigenous+Nursery/@-28.9866208,31.725773,17z/data=!3m1!4b1!4m5!3m4!1s0x1efa0207765001cd:0xb1ef24fea994c6bf!8m2!3d-28.9866208!4d31.7279617'
        ],
        [
          'mdi-email',
          'info@twinstreams.co.za ',
          '',
          'mailto:info@twinstreams.co.za '
        ],
        ['mdi-phone', '074 189 6354', '', 'tel:+27-74-189-6354']
      ],
      socials: [
        [
          'https://www.facebook.com/blockhousekitchen.constantia/',
          'mdi-facebook',
          'Facebook'
        ],
        [
          'https://www.instagram.com/blockhousekitchen/',
          'mdi-instagram',
          'Instagram'
        ],
        ['https://blockhousekitchen.co.za/', 'mdi-web', 'Website']
      ]
    })
  }
</script>

<style scoped>
.v-card {
  border-radius: 0px;
}
</style>
